import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from '../../../api/admin.service';
import { FunctionsService } from '../../../services/functions.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-fast-scanner-qr',
  templateUrl: './fast-scanner-qr.page.html',
  styleUrls: ['./fast-scanner-qr.page.scss'],
})
export class FastScannerQrPage implements OnInit {

  @Input() ads_id;
  @Input() ads_date_id;
  @Input() tickets;
  @Input() bonos;

  open_scanner_qr = true;
  loading_code = false;
  scanner_qr_result = 0;

  optSlidesQr = {
    allowSlidePrev: false,
    allowSlideNext: false,
  };

  constructor(
    public adminService: AdminService,
    public functionService: FunctionsService,
    public modalController: ModalController
  ) { }

  ngOnInit() {}

  validateCodeQr(code){
    if(code){
      this.open_scanner_qr = false;
      this.loading_code = true;
      let params = {
        'code': code,
        'ads_id': this.ads_id,
        'ads_date_id': this.ads_date_id,
        'tickets': this.tickets,
        'bonos': this.bonos,
      };
      this.adminService.validateFastTicket(params)
      .subscribe(
        (response) => {
          this.loading_code = false;

          if(response['success']){
            this.scanner_qr_result = 1;
          }else{
            this.scanner_qr_result = 2;
          }

          setTimeout(() => {
            this.open_scanner_qr = true;
          }, 1500);
        },
        (error) => {
          // error.message
          this.functionService.showDangerToast('Se ha producido un error');
          console.log(error);
        }
      );
    }else{
      // Cerrar modal
      this.closeModal();
    }
  }

  closeModal(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
