export class User{
    id: number;
    name: string;
    role_id: number;
    email: string;
    avatar: string;
    accept_notifications: boolean;

    constructor(id, name, role_id, email, avatar, accept_notifications?){
        this.id = id;
        this.name = name;
        this.role_id = role_id;
        this.email = email;
        this.avatar = avatar;
        this.accept_notifications = accept_notifications;
    }
}
