// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    // api: 'http://localhost:8000/api/',
     api: 'https://api.tiquerapp.com/api/',
    firebaseConfig: {
        apiKey: "AIzaSyCNxSUCn-Dz70M8tcc1LdqsLz34c2bnMBM",
        authDomain: "chollos-95ef2.firebaseapp.com",
        projectId: "chollos-95ef2",
        storageBucket: "chollos-95ef2.appspot.com",
        messagingSenderId: "919297471026",
        appId: "1:919297471026:web:49d8523b6feafbd521422b",
        measurementId: "G-NVBD5JLH60"
    },
    appId: 'com.tiquerapp.tiquer',
    appIdios: 'id1605502814',
    // TEST
    // stripe_public_key: 'pk_test_51L9BdFIFiJBwZ6xwcLSXRVLOIbRjfEiwguuQYly9j5bj5tMov1rJNRfIck32c8kaqhD3qvbiWBvgTitXbF6RqO4I0054bnZVFY'
    // LIVE
    stripe_public_key: 'pk_live_51L9BdFIFiJBwZ6xwR3ugrwUfV24BSoHlHs0p4TvyZxYrjV6qKslnmbofKcbcXjgHcUTWtTAcAunjvBUSCSWyySdB00UjL4WQoc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
