import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutService } from '../../api/checkout.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FunctionsService } from '../../services/functions.service';
import { NavController, ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {

  @Input() tickets_selected;
  @Input() ads_date_id;
  @Input() ads_id;
  @Input() is_required_promotional_code;

  public checkoutForm: FormGroup;
  public loading = true;
  loading_ticket = false;
  ad;
  ads_date_selected;
  tickets;
  user;
  pay_in_app = false;
  one_personal_data = false;
  payment;
  show_info_ticket = false;
  show_info_total = false;
  promotional_code = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkoutService: CheckoutService,
    private formBuilder: FormBuilder,
    private functionsService: FunctionsService,
    public navCtrl:NavController,
    public modalController: ModalController,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  ionViewDidEnter(){
    this.activatedRoute.paramMap.subscribe(param => {
      //let ticket_id = param.get("ticket_id"); // Id del anuncio
      //this.num_tickets = param.get("num_tickets");
      this.loadCheckout();
    });
  }

  buildForm(){
    // Validaciones del formulario
    this.checkoutForm = this.formBuilder.group({
      'name': [null, Validators.compose([
        Validators.required
      ])],
      'dni': [null, Validators.compose([
        Validators.required
      ])]
    },{
      validators: this.validateDNI('dni')
    });
  }

  validateDNI(dni:string){
    return (checkoutForm: FormGroup) => {
      const dniForm = checkoutForm.controls[dni];

      if(dniForm.value){
        if(this.functionsService.validateDNI(dniForm.value)){
          dniForm.setErrors(null);
        }else{
          dniForm.setErrors({noEsIgual: true});
        }
      }else{
        dniForm.setErrors({noEsIgual: true});
      }
    }
  }

  setDataForm(user){
    this.checkoutForm.reset({
      'name': user['name'],
      'dni': user['dni'],
    });
  }

  loadCheckout(){
    let params = {
      'tickets_selected': this.tickets_selected,
      'ads_date_id': this.ads_date_id,
      'ads_id': this.ads_id,
    };
    this.checkoutService.checkout(params)
      .subscribe(
        (response) => {
          if(response['success']){
            console.log(response);
            this.ad = response['data']['ad'];
            this.ads_date_selected = response['data']['ads_date_selected'];
            this.tickets = response['data']['tickets'];
            this.user = response['data']['user'];
            this.one_personal_data = response['data']['one_personal_data'];
            this.pay_in_app = response['data']['pay_in_app'];

            if(this.pay_in_app){
              this.payment = response['data']['payment'];
            }
            if(this.one_personal_data){
              this.setDataForm(this.user); // Autocompletar el formulario con los datos del usuario
            }
            this.loading = false;
          }else{
            this.functionsService.showDangerToast(response['data']['message']);
            this.closeModal();
          }
        },
        (error) => {
          // error.message
          console.log(error);
          this.loading = false;
        }
      );
  }

  validatePersonalData(){
    if(this.one_personal_data){
      // Un solo formulario de datos personales
      return this.checkoutForm.valid;
    }else{
      // Varios datos personales
      let ok = true;
      this.tickets.forEach(function(value, key) {
        let perosnal_data = value['personal_data'];
        perosnal_data.forEach(function(value, key) {
          if(value['name'] == '' || value['dni'] == ''){
            ok = false;
          }
        });
      });
      return ok;
    }
  }

  // Generar el checkout y comporbar en el api si tiene que pagar el usuario en la app o directamente se genera el ticket
  createTicket(){

    if(this.validatePersonalData()){
      this.loading_ticket = true;

      if(this.is_required_promotional_code && !this.promotional_code){
        this.functionsService.showDangerToast('Indica un código promocional');
        this.loading_ticket = false;
        return;
      }

      let params = {
        'tickets': this.tickets,
        'form': this.checkoutForm.value,
        'one_personal_data': this.one_personal_data,
        'ads_date_id': this.ads_date_id,
        'ads_id': this.ads_id,
        'promotional_code': this.promotional_code,
      };

      this.checkoutService.confirmCheckout(params)
        .subscribe(
          (response) => {
            if(response['success']){
              this.loading_ticket = false;
              if(response['data']['pay_in_app']){
                this.navCtrl.navigateForward('/payment/' + response['data']['checkout_id'] + '/' + response['data']['checkout_code']);
                this.closeModal();
              }else{
                this.functionsService.showToast(response['data']['message']);
                this.navCtrl.navigateRoot('/ticket/' + response['data']['checkout_id'] + '/' + response['data']['checkout_code']);
                this.closeModal();
              }
            }else{
              this.loading_ticket = false;
              this.functionsService.showDangerToast(response['data']['message']);
            }
          },
          (error) => {
            // error.message
            console.log(error);
            this.loading_ticket = false;
          }
        );
    }else{
      let error_message = 'Por favor completa los datos de los tickets desde el botón "Rellenar"';
      if(this.one_personal_data){
        error_message = 'Por favor completa los datos del ticket';
      }
      this.showErrorAlert(error_message);
      return;
    }
  }

  async showErrorAlert(message){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  closeModal(params = {}){
    this.modalController.dismiss(params);
  }

  async editTicketPersonalData(personal_data_ticket){
    const alert = await this.alertController.create({
      cssClass: 'alert-ticket-personal-data',
      header: 'Datos del ticket',
      inputs: [
        {
          value: 'Nombre completo*',
          name: 'a1',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'name',
          type: 'text',
          value: (personal_data_ticket['name'] != undefined && personal_data_ticket['name'] != '') ? personal_data_ticket['name'] : null,
        },
        {
          value: 'DNI*',
          name: 'a2',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'dni',
          type: 'text',
          value: (personal_data_ticket['dni'] != undefined && personal_data_ticket['dni'] != '') ? personal_data_ticket['dni'] : null,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          }
        }, {
          text: 'Añadir',
          handler: (event) => {
            console.log(event);
            if(event['name'].trim() != '' && event['dni'].trim() != ''){
              personal_data_ticket['name'] = event['name'];
              personal_data_ticket['dni'] = event['dni'];
            }else{
              this.functionsService.showDangerToast('Completa los datos obligatorios');
            }
          }
        }
      ]
    });

    await alert.present();
  }

  showInfoTicket(){
    this.show_info_ticket = (this.show_info_ticket) ? false : true;
  }

  showInfoTotal(){
    this.show_info_total = (this.show_info_total) ? false : true;
  }

  async showAlertPromotionalCode(){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Código promocional',
      inputs: [
        {
          name: 'promotional_code',
          type: 'text',
          placeholder: 'Escribe el código promocional'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (event) => {
            console.log(event);
            if(event['promotional_code'].trim() != ''){
              this.applyPromotionalCode(event['promotional_code']);
            }
          }
        }
      ]
    });

    await alert.present();
  }

  applyPromotionalCode(code){
    var params = {
      'promotional_code': code,
      'tickets': this.tickets,
    };
    this.checkoutService.applyPromotionalCode(params)
    .subscribe(
      (response) => {
        if(response['success']){
          console.log(response);
          this.payment = response['data']['payment'];
          if(code){
            this.promotional_code = code.toString().toUpperCase();
            this.functionsService.showToast(response['data']['message']);
          }else{
            this.promotional_code = false;
          }
        }else{
          this.functionsService.showDangerToast(response['data']['message']);
        }
      },
      (error) => {
        // error.message
        console.log(error);
      }
    );
  }

  deletePromotionalCode(){
    this.applyPromotionalCode(false);
  }
}
