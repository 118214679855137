import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { FunctionsService } from 'src/app/services/functions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-ticket-ad',
  templateUrl: './form-ticket-ad.component.html',
  styleUrls: ['./form-ticket-ad.component.scss'],
})
export class FormTicketAdComponent implements OnInit {

  @Input() data = [];
  @Input() key = null;
  @Input() is_event = true;
  @Output() returnTicket = new EventEmitter();

  is_bono = 0; // 0 = normal, 1 = bono
  ads_ticket_structure = [];
  ads_ticket_structure_deleted = [];
  id = null;

  public ticketAdForm: FormGroup;

  constructor(
    private alertController: AlertController,
    private functionsService: FunctionsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(){

    // Validaciones del formulario
    this.ticketAdForm = this.formBuilder.group({
      'name': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50),
      ])],
      'description': [null, Validators.compose([])],
      'pvp_price': [null, Validators.compose([
        Validators.required,
        Validators.maxLength(10),
      ])],
      'stock': [1, Validators.compose([
        Validators.required
      ])],
      'start_number_ticket': [1, Validators.compose([
        Validators.required
      ])],
      'is_bono': [(this.is_bono) ? '1' : '0', Validators.compose([
        Validators.required
      ])],
    });

    // Cargar datos
    if(this.key != null){
      this.setDataForm();
    }
  }

  setDataForm(){
    this.ticketAdForm.reset({
      'name': this.data['name'],
      'description': this.data['description'],
      'pvp_price': this.data['pvp_price'],
      'stock': this.data['stock'],
      'start_number_ticket': this.data['start_number_ticket'],
      'is_bono': (this.data['is_bono'] && this.data['is_bono'] != '0') ? '1' : '0',
    });

    this.is_bono = (this.data['is_bono'] && this.data['is_bono'] != '0') ? 1 : 0;
    this.id = this.data['id'];

    if(this.data['is_bono'] == 1){
      this.ads_ticket_structure = this.data['ads_ticket_structure'];
    }
  }

  async alertSectionBono(data, update_key){

    const alert = await this.alertController.create({
      cssClass: 'alert-ads-bono-ticket-data',
      header: 'Configurar bono',
      backdropDismiss: false,
      inputs: [
        {
          value: 'Nombre la sección*',
          name: 'a1',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'name',
          type: 'text',
          value: (data['name'] != undefined) ? data['name'] : null,
        },
        {
          value: 'Descripción (opcional)',
          name: 'a2',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'description',
          type: 'textarea',
          value: (data['description'] != undefined) ? data['description'] : null,
        },
        {
          value: 'Unidades sección*',
          name: 'a4',
          type: 'text',
          cssClass: 'input-label',
        },
        {
          name: 'stock',
          type: 'number',
          value: (data['stock'] != undefined) ? data['stock'] : null,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          }
        }, {
          text: 'Añadir',
          handler: (event) => {
            this.addEditSectionBono(data['id'] ?? null, event, update_key);
          }
        }
      ]
    });

    await alert.present();

  }

  addEditSectionBono(id, data, update_key){
    if(data.name != '' && data.stock != ''){
      let object = {
        'name': data.name,
        'description': data.description,
        'stock': data.stock,
        'id': id,
      };

      if(update_key >= 0){
        // Actualizar datos
        this.ads_ticket_structure[update_key] = object;
      }else{
        // Nuevos datos
        this.ads_ticket_structure.push(object);
      }
    }else{
      this.functionsService.showDangerToast('Completa los datos obligatorios');
    }
  }

  async removeSectionBono(key, name){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar sección ' + name,
      message: '¿Esta seguro?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Eliminar',
          handler: () => {
            if(this.ads_ticket_structure[key]['id'] != undefined){
              this.ads_ticket_structure_deleted.push(this.ads_ticket_structure[key]['id']);
            }
            this.ads_ticket_structure.splice(key, 1);
          }
        }
      ]
    });

    await alert.present();
  }

  changeTicketType(event){
    this.is_bono = (event.detail.value == '0') ? 0 : 1;
  }

  submit(){
    if(!this.ads_ticket_structure && this.is_bono == 1){
      this.functionsService.showDangerToast('Los tickets de tipo bono deben tener al menos una sección.');
    }else{
      if(this.ticketAdForm.valid){
        let data = this.ticketAdForm.value;
        data['key'] = this.key;
        data['id'] = this.id;
        data['ads_ticket_structure'] = this.ads_ticket_structure;
        data['ads_ticket_structure_deleted'] = this.ads_ticket_structure_deleted;

        // Enviar a la vista padre los datos
        this.returnTicket.emit(data);
      }else{
        this.functionsService.showDangerToast('Completa correctamente los datos del formulario');
      }
    }
  }

}
