import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminService } from '../../../api/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.page.html',
  styleUrls: ['./gift.page.scss'],
})
export class GiftPage implements OnInit {
  @Input() ad_id;
  @Input() ads_date_id;
  @Input() ads_tickets;

  public giftForm: FormGroup;

  listTickets = [];
  showForm = false;

  constructor(
    private modalControler: ModalController,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private functionService: FunctionsService
  ) { }

  ngOnInit() {
    console.log(this.ads_tickets);
    this.loadGiftTickets();
    this.buildForm();
  }

  loadGiftTickets(){
    this.adminService.getTicketsListAd(this.ad_id, this.ads_date_id, true)
    .subscribe(
      (response) => {
        console.log(response);
        //this.tickets = response['data']['tickets'];
        this.listTickets = response['data']['tickets'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  buildForm(){
    // Validaciones del formulario
    this.giftForm = this.formBuilder.group({
      'name': ['Regalo Tiquer', Validators.compose([
        Validators.required
      ])],
      'number_tickets': [1, Validators.compose([
        Validators.required
      ])],
      'ticket_id': [null, Validators.compose([
        Validators.required
      ])]
    });
  }

  create(){
    if(this.giftForm.valid){
      let data = this.giftForm.value;
      this.adminService.createGiftTickets(data, this.ads_date_id)
      .subscribe(
        (response) => {
          console.log(response);
          if(response['success']){
            this.functionService.showToast(response['data']['message']);
            this.buttonHideForm();
            this.loadGiftTickets();
          }else{
            this.functionService.showDangerToast(response['data']['message']);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }else{
      this.functionService.showDangerToast('Los datos son incorrectos');
    }
  }

  buttonShowForm(){
    this.showForm = true;
  }

  buttonHideForm(){
    this.showForm = false;
  }

  closeModal(){
    this.modalControler.dismiss();
  }

}
