import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {

  @Output() returnCode = new EventEmitter();

  optSlidesQr = {
    allowSlidePrev: false,
    allowSlideNext: false,
  };
  cancelled = false;
  error = false;

  constructor(
    private barcodeScanner: BarcodeScanner
  ) { }

  ngOnInit() {
    this.openCamera();
  }

  openCamera(){
    this.barcodeScanner.scan().then(barcodeData => {
      // console.log('Barcode data', barcodeData);
      if(barcodeData.cancelled != true){
        let code = barcodeData.text;
        this.returnCode.emit(code);
      }else{
        this.cancelled = true;
      }
     }).catch(err => {
      // this.datosLocal.guardarDato('TEXT', 'CODE_432434'); // Temporal, quitar luego
         console.log('Error', err);
         this.cancelled = true;
         this.error = true;
     });
  }

  volver(){
    this.returnCode.emit(null);
  }
}
