import { Injectable } from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {LoginService} from "./login.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private platform: Platform,
    private loginService: LoginService,
    private router: Router,
    public alertController: AlertController
  ) { }

  public initializePushNotification(){

    // Notificaciones para ios o android
    if (this.platform.is('capacitor') && (this.platform.is('ios') || this.platform.is('android')) ) {
      console.log('android o ios ok login');

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          console.log('Push notifiction apple o google: ');
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          console.log('no hay permisos granted para push notification ');

        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
          (token: Token) => {
            console.log('Push registration success, token: ');
            console.log( token.value);

            // Registrar en nuestra API
            const user = JSON.parse(localStorage.getItem('user'));
            if (user != null) {
              this.loginService.registerFirebaseToken(token.value)
                  .subscribe(
                      (response) => {
                        // this.ionLoader.hideLoader();
                        if (response['success']) {
                          console.log('registrado firebase token OK');
                        } else {
                          console.log(response['data']['error_message']);

                          // this.navCtrl.navigateForward('/home-results');
                        }
                      },
                      (error) => {
                        console.log('bad register firebase token');
                      }
                  );
            } else {
              // Registrar dispositivos sin usuario logado (desconocidos crear tabla)
            }

          }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log('Error on registration: ');
            console.log(JSON.stringify(error));
          }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            console.log('Push received: ' );
            console.log(JSON.stringify(notification));

            console.log(notification.data.eventoId)
            console.log(notification.data.ofertaId)
            console.log(notification.data.bonoId)

              this.notificationReceived(notification);

          }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
              console.log('Push action performed: ' );
              console.log( JSON.stringify(notification));

              console.log(notification.notification.data.eventoId)
              console.log(notification.notification.data.ofertaId)
              console.log(notification.notification.data.bonoId)
              if(notification.notification.data.eventoId){
                  // se deja la ultima barra porque es asi => 'evento/:id/:slug'
                  // en este caso el slug es vacio
                  this.router.navigateByUrl(`/evento/${notification.notification.data.eventoId}/`);
              }else if(notification.notification.data.ofertaId){
                  // se deja la ultima barra porque es asi => 'oferta/:id/:slug'
                  // en este caso el slug es vacio
                  this.router.navigateByUrl(`/oferta/${notification.notification.data.ofertaId}/`);
              }else if(notification.notification.data.bonoId){
                  // se deja la ultima barra porque es asi => 'bono/:id/:slug'
                  // en este caso el slug es vacio
                  this.router.navigateByUrl(`/bono/${notification.notification.data.bonoId}/`);
              }

          }
      );

    }

  }

  private async notificationReceived(notification){
      if(notification.data.eventoId || notification.data.ofertaId || notification.data.bonoId){
          const alert = await this.alertController.create({
              cssClass: 'my-custom-class',
              header: notification.title,
              message: notification.body,
              buttons: [
                  {
                      text: 'Cerrar',
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: (blah) => {}
                  }, {
                      text: 'Ver',
                      handler: () => {
                          if(notification.data.eventoId){
                              // se deja la ultima barra porque es asi => 'evento/:id/:slug'
                              // en este caso el slug es vacio
                              this.router.navigateByUrl(`/evento/${notification.data.eventoId}/`);
                          }else if(notification.data.ofertaId){
                              // se deja la ultima barra porque es asi => 'oferta/:id/:slug'
                              // en este caso el slug es vacio
                              this.router.navigateByUrl(`/oferta/${notification.data.ofertaId}/`);
                          }else if(notification.data.bonoId){
                              // se deja la ultima barra porque es asi => 'bono/:id/:slug'
                              // en este caso el slug es vacio
                              this.router.navigateByUrl(`/bono/${notification.data.bonoId}/`);
                          }
                       }
                  }
              ]
          });

          await alert.present();
      }
  }

}
