import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './api/login.service';
import { Router } from '@angular/router';

import {LanguageService} from './services/language.service';

import { Pages } from './interfaces/pages';
import { Location } from '@angular/common';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {PushNotificationService} from "./api/pushNotification.service";
import {UpdateApplicationService} from "./api/updateApplication.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages: Array<Pages>;
  // user;
  avatar: string;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    public translate: TranslateService,
    private languageService: LanguageService,
    private loginService: LoginService,
    public router: Router,
    public location: Location,
    private pushNotificationService: PushNotificationService,

    private updateApplicationService: UpdateApplicationService
    // public events: Events
  ) {

    // Si es web inicializamos google auth =>  Comprobar si es nativo TRUE o no FALSE
    if (!this.platform.is('capacitor')) {
      GoogleAuth.init();
    }

    console.log(this.platform.platforms());

    // translate.setDefaultLang('es');
    translate.setDefaultLang(this.languageService.getLanguage());

    this.appPages = [
      {
        title: 'Home',
        url: '/home-results',
        direct: 'root',
        icon: 'home'
      },
      {
        title: 'About',
        url: '/about',
        direct: 'forward',
        icon: 'information-circle-outline'
      },

      {
        title: 'App Settings',
        url: '/settings',
        direct: 'forward',
        icon: 'cog'
      }
    ];

    this.initializeApp();

    // router.events.subscribe(event => {
    //     //   if (event instanceof NavigationEnd) {
    //     //     this.ngOnInit();
    //     //   }
    //     // });

    // generamos un evento que lanzamos cuando queremos trabajar con el app nav menu
    /* this.events.subscribe('updateMenu', () => {
      console.log('force update the screen');
      this.ngOnInit();
    }); */
  }

  ngOnInit() {

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      // inicializar push notifications
      this.pushNotificationService.initializePushNotification();
      // comprobar si la aplicación necesita ser actualizada de forma urgente
      this.updateApplicationService.checkUpdateApplication();

    }).catch(() => {});

    // Para salirnos completamente de la app desde el smartphone (android) cuando se pulsa el botón atrás
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      // Cerrar la aplicación desde las rutas indicadas
      if (this.location.isCurrentPathEqualTo('/tabs/home') || this.location.isCurrentPathEqualTo('/splash')) {
        navigator['app'].exitApp();
      } else {
        // Navigate to back page
        this.location.back();
      }
    });
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    console.log("logout app.component");

    // Cierro sesion, elimino firebase token y elimino localStorage data
    this.loginService.processLogout();
  }// logout
}
