import { Injectable } from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {LoginService} from "./login.service";
import {Router} from "@angular/router";

// Abrir navegador
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppVersion} from "@ionic-native/app-version/ngx";

import { Market } from '@ionic-native/market/ngx';

// dto para la obtención de datos de la api
interface AppUpdate {
    current_version: string;
    enabled: boolean;
    maintenanceMsg?: {
        title: string;
        msg: string;
        btn: string;
    };
    majorMsg?: {
        title: string;
        msg: string;
        btn: string;
    };
    minorMsg?: {
        title: string;
        msg: string;
        btn: string;
    };
}

@Injectable({
  providedIn: 'root'
})
export class UpdateApplicationService {
    // Identificador de android e ios
    public appId: string = environment.appId;
    public appIdios: string = environment.appIdios;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private alertController: AlertController,
    private appVersion: AppVersion,
    private market: Market
  ) { }

  public checkUpdateApplication(){

    // Notificaciones para ios o android
    if ( this.platform.is('ios') || this.platform.is('android') ) {
      console.log('android o ios check update application');

        this.getUpdateApplication();
    }

  }

    getUpdateApplication() {
        const query = "check_update_application";
        const url = environment.api;
        const headers = new HttpHeaders({
            "Content-Type": "application/json"
        });

        this.http.get(url + query, { headers }).subscribe(async (info: AppUpdate) => {

            console.log(info);

            if(!info.enabled){
                this.PresentAlert(info.maintenanceMsg.title, info.maintenanceMsg.msg, info.maintenanceMsg.btn);
            }else{
                // version de ios o android 1.2.0
                const versionNumber = await this.appVersion.getVersionNumber();
                const splitterNumberVersion  = versionNumber.split('.');
                console.log('Version Clientes');
                console.log(versionNumber);

                // version que indicamos en servidor por ejemplo 2.5.0
                const serverVersion = info.current_version.split('.');
                console.log('Version Servidor');
                console.log(serverVersion);
                // major version cambios grandes y que necesita actualizar ya
                if(serverVersion[0] > splitterNumberVersion[0]){
                    // major version
                    this.PresentAlert(info.majorMsg.title, info.majorMsg.msg, info.majorMsg.btn);
                }else if (serverVersion[1] > splitterNumberVersion[1]){
                    // minor version
                    this.PresentAlert(info.minorMsg.title, info.minorMsg.msg, info.minorMsg.btn, true);
                }
            }
        });
    }

  private async PresentAlert(title, body, buttonText = '', allowClose = false){

      const buttonsList: any = [];

      if(buttonText !== ''){
          buttonsList.push({
              text: buttonText,
              role: 'confirm',
              cssClass: 'secondary',
              handler: () => {
                  this.openAppStoreEntry();
              }
          });
      }

      if(allowClose){
          buttonsList.push({
              text: 'Cerrar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
          });
      }

      const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: title,
          message: body,
          buttons: buttonsList,
          backdropDismiss: allowClose
      });

      await alert.present();

  }

  openAppStoreEntry(){
    console.log('openAppStoreEntry')
    // es para ios y android

    if ( this.platform.is('android') ) {
       this.market.open(this.appId);
    }else{
       this.market.open(this.appIdios);
    }
  }

}
