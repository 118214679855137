import { Injectable } from '@angular/core';
import { NavController, ToastController, LoadingController, AlertController, Platform } from '@ionic/angular';

import { Location } from "@angular/common";
import { LoginService } from '../api/login.service';
import { User } from '../interfaces/users';
import { Title } from '@angular/platform-browser';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
    public navCtrl: NavController,
    private location: Location,
    private loginService: LoginService,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private titleService: Title,
    public alertController: AlertController,
    private clipboard: Clipboard,
    private platform: Platform,
  ) { }

  is_user_business(role_id){
    return this.loginService.is_user_business(role_id);
  }

  is_admin(role_id){
    return this.loginService.is_admin(role_id);
  }

  is_normal_user(role_id){
    return this.loginService.is_normal_user(role_id);
  }

  goToLogin(){
    this.navCtrl.navigateRoot('/login');
  }

  // Función para comprobar a que página ir despues del login dependiendo si es la primera vez que se entra o no
  goToHome(go_to_back_page = false){
    let user_session:User = this.loginService.getUserSession();
    if(go_to_back_page){
      // Redirrigir a la anterior página antes de entrar al login/registro
      if(!user_session.role_id || this.is_normal_user(user_session.role_id)){
        this.location.back();
      }else{
        // Rol business (3) y rol admin (1)
        this.navCtrl.navigateRoot('/admin/tabs/scanner');
      }
    }else{
      if(!user_session.role_id || this.is_normal_user(user_session.role_id)){
        // Rol usuarios (2)
        if(this.showPolicy()){
          if(this.showTutorial()){
            if(this.showCities()){
              this.navCtrl.navigateRoot('/tabs/home');
            }
          }
        }
      }else{
        // Rol business (3) y rol admin (1)
        this.navCtrl.navigateRoot('/admin/tabs/scanner');
      }
    }
  }

  showPolicy(){
    if(!this.loginService.getPolicy()){

      this.navCtrl.navigateRoot('/policy');
      return false;

    }else{

      return true;

    }
  }

  showTutorial(){
    if(!this.loginService.getTutorial()){

      this.navCtrl.navigateRoot('/tutorial');
      return false;

    }else{

      return true;

    }
  }

  showCities(){
    if(!this.loginService.getCity()){

      this.navCtrl.navigateRoot('/city');
      return false;

    }else{

      return true;

    }
  }

  async showToast(message, color = 'success') {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: color
    });
    toast.present();
  }
  async showDangerToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  getHistorySearch(){
    let history_search = localStorage.getItem('history_search');
    return (history_search) ? JSON.parse(history_search) : [];
  }

  setHistorySearch(history_search, string){
    if(history_search.filter(element => element == string).length == 0){ // No duplicar el mismo string
      if(history_search.length == 10){
        history_search.pop(); // Eliminar último elemento
      }
      history_search.unshift(string); // Insertar en la primera posición

      localStorage.setItem('history_search', JSON.stringify(history_search));
    }
    return history_search;
  }

  deleteHistorySearchItem(history_search, index){
    history_search.splice(index, 1);
    localStorage.setItem('history_search', JSON.stringify(history_search));
    return history_search;
  }

  // Validar formato del DNI
  validateDNI(dni:string){
    let validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'; // Letra1
    let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i; // NIF (Numero de Identificación Fiscal) - 8 numeros y una Letra1
    let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i; // NIE (Numero de Identificación de Extranjeros) - 1 letra (XYZ), 7 numeros y 1 Letra1
    let str = dni.toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    // Comporbar si es real
    // Se divide el numero entre 23 y si el resto coincide con la posicion de la letra del documento en la lista TRWAGMYFPDXBNJZSQVHLCKE entonces es valido.
    let nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }

  // Show the loader for infinite time
  showLoader() {

    this.loadingController.create({
      message: 'Cargando...'
    }).then((res) => {
      res.present();
    });

  }

  // Hide the loader if already created otherwise return error
  hideLoader() {

    this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });

  }

  // Establecer el title de la pagina en la web
  setPageTitle(title = ''){
    if(title){
      this.titleService.setTitle(title + ' | Tiquer');
    }else{
      this.titleService.setTitle('Tiquer');
    }
  }

  isEvent(type){
    return (type == 2) ? true : false;
  }

  async showSimpleAlert(message, title = null, subtitle = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      subHeader: subtitle,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  // Saber si estamos en una app nativa (con capacitor) instalada en un iphone o android o estamos en web
  isNativeAppInDevice(){
    if(this.platform.is('capacitor') && (this.platform.is('ios') || this.platform.is('android'))){
      // Dispositivo nativo iOS o Android
      return true;
    }else{
      // Web u otro
      return false;
    }
  }

  // Copiar al portapapeles
  copyToClipboard(val){
    if(this.isNativeAppInDevice()){
      // Desde app nativa (con capacitor)
      this.clipboard.copy(val);
    }else{
      // Desde web
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    this.showToast('Copiado en el portapapeles', 'primary');
  }
}
