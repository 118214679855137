import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanLoad } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:show_register',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:show_register/:go_back',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  /* {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  }, */
  /* {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  }, */
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  /* {
    path: 'home-results',
    loadChildren: () => import('./pages/home-results/home-results.module').then( m => m.HomeResultsPageModule)
  }, */
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule),
    canLoad: [ LoginGuard ]
  },
  /* {
    path: 'anuncio/:id',
    loadChildren: () => import('./pages/ad/ad.module').then( m => m.AdPageModule)
  },
  {
    path: 'anuncio/:id/:slug',
    loadChildren: () => import('./pages/ad/ad.module').then( m => m.AdPageModule)
  }, */
  {
    path: 'oferta/:id/:slug',
    loadChildren: () => import('./pages/ad/ad.module').then( m => m.AdPageModule)
  },
  {
    path: 'evento/:id/:slug',
    loadChildren: () => import('./pages/ad/ad.module').then( m => m.AdPageModule)
  },
  {
    path: 'bono/:id/:slug',
    loadChildren: () => import('./pages/ad/ad.module').then( m => m.AdPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'policy/:from_settings',
    loadChildren: () => import('./pages/policy/policy.module').then( m => m.PolicyPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'tutorial/:from_settings',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'city',
    loadChildren: () => import('./pages/city/city.module').then( m => m.CityPageModule)
  },
  {
    path: 'city/:from_settings',
    loadChildren: () => import('./pages/city/city.module').then( m => m.CityPageModule)
  },
  /* {
    path: 'checkout/:ticket_id/:num_tickets',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canLoad: [ AuthGuard ]
  }, */
  {
    path: 'ticket/:checkout_id/:checkout_code',
    loadChildren: () => import('./pages/ticket/ticket.module').then( m => m.TicketPageModule),
    canLoad: [ AuthGuard ]
  },
  {
    path: 'admin/ticket-scanner',
    loadChildren: () => import('./pages/admin/ticket-scanner/ticket-scanner.module').then( m => m.TicketScannerPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/tabs',
    loadChildren: () => import('./pages/admin/tabs/tabs.module').then( m => m.TabsPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/config-ad/:id',
    loadChildren: () => import('./pages/admin/config-ad/config-ad.module').then( m => m.ConfigAdPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'payment/:checkout_id/:checkout_code',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule),
    canLoad: [ AuthGuard ]
  },
  {
    path: 'payment-finished/:checkout_id/:checkout_code',
    loadChildren: () => import('./pages/payment-finished/payment-finished.module').then( m => m.PaymentFinishedPageModule),
    canLoad: [ AuthGuard ]
  },
  {
    path: 'admin/tickets-ad/:id/:ads_date_id',
    loadChildren: () => import('./pages/admin/tickets-ad/tickets-ad.module').then( m => m.TicketsAdPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/employees',
    loadChildren: () => import('./pages/admin/employees/employees.module').then( m => m.EmployeesPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/account',
    loadChildren: () => import('./pages/admin/account/account.module').then( m => m.AccountPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'check-gift',
    loadChildren: () => import('./pages/check-gift/check-gift.module').then( m => m.CheckGiftPageModule),
    canLoad: [ AuthGuard ]
  },
  {
    path: 'admin/promotional-codes/:ad_id',
    loadChildren: () => import('./pages/admin/promotional-codes/promotional-codes.module').then( m => m.PromotionalCodesPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/promotional-codes-form/:ad_id',
    loadChildren: () => import('./pages/admin/promotional-codes-form/promotional-codes-form.module').then( m => m.PromotionalCodesFormPageModule),
    canLoad: [ AdminGuard ]
  },
  {
    path: 'admin/promotional-codes-form/:ad_id/:id',
    loadChildren: () => import('./pages/admin/promotional-codes-form/promotional-codes-form.module').then( m => m.PromotionalCodesFormPageModule),
    canLoad: [ AdminGuard ]
  },








  /* {
    path: 'user-tickets',
    loadChildren: () => import('./pages/user-tickets/user-tickets.module').then( m => m.UserTicketsPageModule)
  }, */




  /* {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  }, */





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
