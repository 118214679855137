import { Component, Input, OnInit } from '@angular/core';
import { CheckoutService } from '../../../api/checkout.service';
import { FunctionsService } from '../../../services/functions.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ticket-bono-details',
  templateUrl: './ticket-bono-details.page.html',
  styleUrls: ['./ticket-bono-details.page.scss'],
})
export class TicketBonoDetailsPage implements OnInit {

  @Input() ticket_code;
  bono = [];

  constructor(
    private checkoutService: CheckoutService,
    private functionsService: FunctionsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.functionsService.showLoader();
    this.checkoutService.get_user_ticket_bono_details(this.ticket_code)
    .subscribe(
      (response) => {
        console.log(response);
        this.bono = response['data']['bono_structure'];

        this.functionsService.hideLoader();
      },
      (error) => {
        // error.message
        console.log(error);
        this.functionsService.hideLoader();
      }
    );
  }

  showInfo(date = null){
    if(date){
      this.functionsService.showSimpleAlert(date, 'Gastado', '');
    }else{
      this.functionsService.showSimpleAlert('', 'Disponible', '');
    }
  }

  showInfoBono(){
    let message = '<p>En esta pantalla se muestra el contenido de tu bono. Esta pantalla es informativa para tí. Para canjear el bono entero o una parte, debes mostrar en el establecimiento el código QR de la pantalla anterior.</p>';
    message += '<p>Los iconos en color verde es lo que te queda disponible</p>';
    message += '<p>Los iconos en color gris es lo que ya has gastado de tu bono</p>';
    this.functionsService.showSimpleAlert(message, 'Información', '');
  }

  closeModal(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
